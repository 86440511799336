import React from 'react'
import {
  Activity,
  Briefcase,
  Layers,
  Target,
  TrendingUp,
  User as MuiUserIcon,
  Users as MuiUsersIcon,
  Search,
  Octagon,
  Globe,
  Link,
  Zap,
  Inbox,
  Phone,
  Cloud,
  Grid,
  UserPlus,
  Archive,
  Key,
  Percent,
} from 'react-feather'
import { Action } from '../pages/Action'
import { Brands } from '../pages/Brands'
import { Campaign } from '../pages/Campaign'
import { Campaigns } from '../pages/Campaigns'
import { ContactRequest } from '../pages/ContactRequest'
import { ContactRequests } from '../pages/ContactRequests'
import { Entities } from '../pages/Entities'
import { Entity } from '../pages/Entity'
import { Contact } from '../pages/Contact'
import { Contacts } from '../pages/Contacts'
import { Channels } from '../pages/Channels'
import { Events } from '../pages/Events'
import { Users } from '../pages/Users'
import { Creators } from '../pages/Creators'
import { Brand } from '../pages/Brand'
import { Partner } from '../pages/Partner'
import { Campaign as TrackingCampaign } from '../pages/TrackingCampaign'
import { Channel } from '../pages/Channel'
import { Event } from '../pages/Event'
import { Opportunity } from '../pages/Opportunity'
import { User } from '../pages/User'
import { Creator } from '../pages/Creator'
import { Research } from '../pages/Research'
import { Researches } from '../pages/Researches'
import { Searches } from '../pages/Searches'
import { Signature } from '../pages/Signature'
import { Signatures } from '../pages/Signatures'

import { Ecosystem } from '../pages/Ecosystem'
import { Ecosystems } from '../pages/Ecosystems'

import { HostBlocklist } from '../pages/HostBlocklist'
import { SimilarWebQuotas } from '../pages/SimilarWebQuotas'

import { Signin } from '../pages/Signin'
import ac from '../permissions'

export const authRoutes = [
  {
    id: 'signin',
    name: 'SignIn',
    path: '/signin',
    component: Signin,
    children: null,
  },
]

export const sideBarTrackerRoutes = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    icon: <Cloud />,
    onClick: (e) => {
      e.preventDefault()
      window.open('https://admin.breezy.io/dashboard', '_blank')
    },
  },
  {
    id: 'ecosystems-list',
    name: 'Ecosystems',
    path: '/ecosystems',
    icon: <Grid />,
    component: Ecosystems,
    children: null,
  },
  // {
  //   id: 'actions-list',
  //   name: 'Actions',
  //   path: '/actions',
  //   icon: <Target />,
  //   component: Actions,
  //   children: null
  // },
  // {
  //   id: 'analytics-list',
  //   name: 'Analytics',
  //   path: '/analytics',
  //   icon: <Activity />,
  //   component: Analytics,
  //   children: null
  // },
  {
    id: 'brands-list',
    name: 'Brands',
    path: '/brands',
    icon: <Briefcase />,
    component: Brands,
    children: null,
  },
  // {
  //   id: 'partners-list',
  //   name: 'Partners',
  //   path: '/partners',
  //   icon: <MuiUserIcon />,
  //   component: Partners,
  //   children: null
  // },
  {
    id: 'campaigns-list',
    name: 'Campaigns',
    path: '/campaigns',
    icon: <Link />,
    component: Campaigns,
    children: null,
  },
  // {
  //   id: 'tracking-campaigns-list',
  //   name: 'Tracking Campaigns',
  //   path: '/tracking-campaigns',
  //   icon: <TrendingUp />,
  //   component: TrackingCampaigns,
  //   children: null
  // },
  {
    id: 'contact-requests-list',
    name: 'Contact Requests',
    path: '/contact-requests',
    icon: <UserPlus />,
    component: ContactRequests,
    children: null,
  },
  {
    id: 'contacts-list',
    name: 'Contacts',
    path: '/contacts',
    icon: <Phone />,
    component: Contacts,
    children: null,
  },
  {
    id: 'entities-list',
    name: 'Entities',
    path: '/entities',
    icon: <Phone />,
    component: Entities,
    children: null,
  },
  {
    id: 'channels-list',
    name: 'Channels',
    path: '/channels',
    icon: <Globe />,
    component: Channels,
    children: null,
  },
  {
    id: 'events-list',
    name: 'Events',
    path: '/events',
    icon: <Zap />,
    component: Events,
    children: null,
  },
  // {
  //   id: 'opportunities-list',
  //   name: 'Opportunities',
  //   path: '/opportunities',
  //   icon: <Layers />,
  //   component: Opportunities,
  //   children: null
  // },
  {
    id: 'users-list',
    name: 'Users',
    path: '/users',
    icon: <MuiUsersIcon />,
    component: Users,
    children: null,
  },
  {
    id: 'creators-list',
    name: 'Creators',
    path: '/creators',
    icon: <MuiUsersIcon />,
    component: Creators,
    children: null,
  },
  {
    id: 'researches-list',
    name: 'Researches',
    path: '/researches',
    icon: <Archive />,
    component: Researches,
    children: null,
  },
  {
    id: 'searches-list',
    name: 'Searches',
    path: '/searches',
    icon: <Search />,
    component: Searches,
    children: null,
  },
  {
    id: 'signatures-list',
    name: 'Signatures',
    path: '/signatures',
    icon: <Key />,
    component: Signatures,
    children: null,
  },
  // {
  //   id: 'host-blocklist',
  //   name: 'Global Host Blocklist',
  //   path: '/host-blocklist',
  //   icon: <Octagon />,
  //   component: HostBlocklist,
  //   children: null
  // },
  {
    id: 'similarweb-quotas',
    name: 'Similarweb Quotas',
    path: '/similarweb-quotas',
    icon: <Percent />,
    component: SimilarWebQuotas,
    children: null,
  },
]

export const sideBarDiscoveryRoutes = []

export const dashboardTrackerRoutes = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    icon: <Briefcase />,
    onClick: (e) => {
      e.preventDefault()
    },
  },
  {
    id: 'brands-card',
    name: 'Brands',
    path: '/brands/:id/:channel?',
    icon: <Briefcase />,
    component: Brand,
    children: null,
  },
  {
    id: 'partners-card',
    name: 'Partners',
    path: '/partners/:id/:channel?',
    icon: <MuiUserIcon />,
    component: Partner,
    children: null,
  },
  {
    id: 'campaign-card',
    name: 'Campaign',
    path: '/campaigns/:id',
    icon: <Link />,
    component: Campaign,
    children: null,
  },
  {
    id: 'tracking-campaigns-card',
    name: 'Tracking Campaigns',
    path: '/tracking-campaigns/:id',
    icon: <TrendingUp />,
    component: TrackingCampaign,
    children: null,
  },
  {
    id: 'contact-requests-card',
    name: 'Contact Requests',
    path: '/contact-requests/:id',
    icon: <TrendingUp />,
    component: ContactRequest,
    children: null,
  },
  {
    id: 'contact-card',
    name: 'Contact',
    path: '/contacts/:id',
    icon: <Phone />,
    component: Contact,
    children: null,
  },
  {
    id: 'entity-card',
    name: 'Entity',
    path: '/entities/:id',
    icon: <Phone />,
    component: Entity,
    children: null,
  },
  {
    id: 'channel-card',
    name: 'Channels',
    path: '/channels/:id',
    icon: <Globe />,
    component: Channel,
    children: null,
  },
  {
    id: 'actions-card',
    name: 'Actions',
    path: '/actions/:id',
    icon: <Target />,
    component: Action,
    children: null,
  },
  {
    id: 'events-card',
    name: 'Events',
    path: '/events/:id',
    icon: <Zap />,
    component: Event,
    children: null,
  },
  {
    id: 'opportunities-card',
    name: 'Opportunities',
    path: '/opportunities/:id',
    icon: <Layers />,
    component: Opportunity,
    children: null,
  },
  {
    id: 'users-card',
    name: 'Users',
    path: '/users/:id',
    icon: <MuiUsersIcon />,
    component: User,
    children: null,
  },
  {
    id: 'creators-card',
    name: 'Creators',
    path: '/creators/:id',
    icon: <MuiUsersIcon />,
    component: Creator,
    children: null,
  },
  {
    id: 'researches-card',
    name: 'Researches',
    path: '/researches/:id',
    icon: <Search />,
    component: Research,
    children: null,
  },
  {
    id: 'signatures-card',
    name: 'Signatures',
    path: '/signatures/:id',
    icon: <Search />,
    component: Signature,
    children: null,
  },
  {
    id: 'ecosystem-card',
    name: 'Ecosystems',
    path: '/ecosystems/:id',
    icon: <Search />,
    component: Ecosystem,
    children: null,
  },
]

export const dashboardDiscoveryRoutes = [
  // {
  //   id: 'discovery-brands-card',
  //   name: 'Brands',
  //   path: '/discovery/brands/:id/:channel?',
  //   icon: <Briefcase />,
  //   component: DiscoveryBrand
  // },
  // {
  //   id: 'discovery-partners-card',
  //   name: 'Partners',
  //   path: '/discovery/partners/:id/:channel?',
  //   icon: <MuiUserIcon />,
  //   component: DiscoveryPartner
  // },
  // {
  //   id: 'researches-list',
  //   name: 'Researches',
  //   path: '/discovery/researches/:id',
  //   icon: <MuiUsersIcon />,
  //   component: Research,
  //   children: null
  // },
]

export const roleRoutes = (routes, role) => {
  if (!role) {
    return []
  }
  return routes.filter(({ id }) =>
    ac
      .can(role)
      ['readAny']('Routes')
      .filter({ [id]: true })
      .hasOwnProperty(id)
  )
}
