import { Delete, Get, Post, Put } from '../utils/request'
import { observable, action, computed } from 'mobx'
import { formatCurrency, formatDateTime } from '../utils/format'
import hasError from './request-message'
import config from '../config'

// brand: "0l8YXNXXN"
// brandName: "Test Affiliate 2ddddd"
// counters: {alexa: 6, mozLinks: 0, mozMetrics: 21, zenserp: 1}
// createdAt: "2020-08-26T11:59:11.380Z"
// finishedAt: "2020-08-26T11:59:22.073Z"
// id: "z1F4yOWgN"
// product: "r4x4ZIgdNc"
// productName: "dfgdfgdgf"
// settings: {hostScoreWeightings: {hostQuality: 50, hostQuantity: 30, ubiquitousHost: 20},…}
// status: "done"

// counters: {alexa: 6, mozLinks: 0, mozMetrics: 21, zenserp: 1}
// alexa: 6
// mozLinks: 0
// mozMetrics: 21
// zenserp: 1

// hostScoreWeightings: {hostQuality: 50, hostQuantity: 30, ubiquitousHost: 20}
// hostQuality: 50
// hostQuantity: 30
// ubiquitousHost: 20
// urlScoreWeightings: {competitorResearch: 25, keywordResearch: 25, ubiquitousUrl: 30, urlKeywords: 20}
// competitorResearch: 25
// keywordResearch: 25
// ubiquitousUrl: 30
// urlKeywords: 20

class ResearchStore {
  @observable id
  @observable brand
  @observable brandName
  @observable product
  @observable searchId
  @observable searchName

  @observable researchId

  @observable settings = { hostScoreWeightings: {}, urlScoreWeightings: {} }
  @observable counters = {}
  @observable costs = {}

  @observable createdAt
  @observable finishedAt

  @observable status

  @observable loading = true
  @observable thinking = false

  constructor (id) {
    this.id = id
    this.load().then()
  }

  @action
  async load () {
    const { errorCode, discoveryResearch, message } = await Get(`/api/admin/discovery-researches/${this.id}`)

    if (!hasError(!errorCode, message, '')) {
      for (let key in discoveryResearch) {
        if (discoveryResearch.hasOwnProperty(key)) {
          this[key] = discoveryResearch[key]
        }
      }
    }

    this.loading = false
  }

  @computed
  get details () {
    const {
      id, brand, brandName, product, searchName, searchId, researchId, createdAt, finishedAt, swUsedQuota, costs, status, settings, counters
    } = this
    return {
      id, brand, brandName, product, searchName, researchId, swUsedQuota,
      cost:  `${config.NUMBER_CURRENCY_SYMBOL}${((costs || {}).total || 0).toFixed(4)}`,
      createdAt: formatDateTime(createdAt), finishedAt: status !== 'done' ? '' : formatDateTime(finishedAt), status,
      ...settings.hostScoreWeightings, ...settings.urlScoreWeightings,
      ...counters,
      searchId
    }
  }
}

export default ResearchStore
