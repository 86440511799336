import { Get } from '../utils/request'
import QueryString from 'query-string'
import { observable, action, computed, toJS } from 'mobx'
import _ from 'lodash'
import param from 'can-param'
import moment from 'moment'
import hasError from './request-message'

export default class List {
  url
  itemsField
  additionalFields

  @observable items = []
  @observable search
  @observable filters = {}
  @observable all
  // @observable order = 'desc'
  // @observable orderBy = '-createdAt'
  @observable page = 0
  @observable perPage = 25
  @observable totalCount
  @observable seachOutOfFilters = false

  @observable initList = true
  @observable thinking = true

  constructor(filters = {}) {
    this.filters = filters
  }

  @computed
  get combinedUrl() {
    const { search, page, perPage } = this
    const query = _.omitBy(
      {
        page: page + 1,
        perPage,
        filters: this._filters,
        sort: this.sort || undefined,
        ...(!!search && { search }),
        ...(this.all && { all: true }),
      },
      (v) => _.isUndefined(v) || _.isNull(v)
    )
    return `${this.url}?${param(query)}`
  }

  @computed
  get _filters() {
    let result = undefined

    for (let key in this.filters) {
      if (
        this.filters.hasOwnProperty(key) &&
        this.filters[key] &&
        this.filters[key] !== 'all'
      ) {
        result = result || {}
        result[key] = this.filters[key]
      }
    }

    if (result && result.period) {
      result = {
        ...result,
        ...(result.period.startDate && {
          start: moment(result.period.startDate)
            .format('YYYY-MM-DD')
            .toString(),
        }),
        ...(result.period.endDate && {
          end: moment(result.period.endDate).format('YYYY-MM-DD').toString(),
        }),
      }

      delete result.period
    }

    if (result && result.payoutPeriod) {
      result = {
        ...result,
        ...(result.payoutPeriod.startDate && {
          payoutStart: moment(result.payoutPeriod.startDate)
            .format('YYYY-MM-DD')
            .toString(),
        }),
        ...(result.payoutPeriod.endDate && {
          payoutEnd: moment(result.payoutPeriod.endDate)
            .format('YYYY-MM-DD')
            .toString(),
        }),
      }

      delete result.payoutPeriod
    }

    return result
  }

  @computed
  get sort() {
    return this.orderBy
      ? `${this.order === 'desc' ? '-' : ''}${this.orderBy}`
      : null
  }

  @computed
  get listProps() {
    const {
      items,
      initList: initialization,
      thinking,
      totalCount: total,
      search,
      page,
      perPage,
      order,
      orderBy,
      filters,
    } = this
    return {
      items: toJS(items),
      initialization,
      thinking,
      search,
      total,
      page,
      perPage,
      order,
      orderBy,
      filters: toJS(filters),
    }
  }

  @action
  async getList() {
    this.thinking = true
    const {
      errorCode,
      message,
      [this.itemsField]: items,
      totalCount,
      perPage,
      ...restFields
    } = await Get(this.combinedUrl)

    if (!hasError(!errorCode, message)) {
      this.items = items
      this.totalCount = totalCount
      this.perPage = perPage

      if (this.additionalFields) {
        this.additionalFields.forEach((field) => {
          this[field] = restFields[field]
        })
      }
    }

    this.thinking = false
    this.initList = false

    return errorCode
  }

  @action
  onChangeSort(order, orderBy) {
    this.order = order
    this.orderBy = orderBy
    this.getList().then()
  }

  @action
  onChangePage(page) {
    this.page = page
    this.getList().then()
  }

  @action
  onChangeRowsPerPage(perPage) {
    this.page = 0
    this.perPage = perPage
    this.getList().then()
  }

  @action
  onChangeFilter(name, value) {
    let _filters = toJS(this.filters)

    // if (name === 'search') {
    //   this.search = !!value ? value : ''
    // } else
    if (!value) {
      delete _filters[name]
    } else {
      _filters[name] = value
    }

    console.log(_filters)

    this.filters = _filters
    this.page = 0
    this.getList().then()
  }

  @action
  onChangeFilterGroup(filters) {
    this.filters = filters
    this.page = 0
    this.getList().then()
  }

  @action
  onSearchChange(search) {
    this.search = search
    this.page = 0
    this.getList().then()
  }
}
