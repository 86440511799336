import React from 'react'
import { spacing } from '@material-ui/system'
import { Button as MuiButton } from '@material-ui/core'
import styled from 'styled-components'
import DashboardPage from '../../layouts/DashboardPage'

import ConfirmDialog from '../../components/ConfirmDialog'
import { RejectButton } from '../../components/Buttons'
import DetailsForm from '../../components/Details'

import Contacts from './Contacts'
import ChannelEntity from '../../components/Channels/ChannelEntity'
import ChannelContacts from '../../components/Channels/ChannelContacts'

const Button = styled(MuiButton)(spacing)

const config = [
  { key: 'id', name: 'ID', editable: false, creatable: false, type: 'string' },
  // {
  //   key: 'partner', name: 'Partner', editable: false, creatable: true, type: 'partners',
  //   link: { title: 'Partner', pattern: `/partners/{id}`, keys: { id: 'partner' } }
  // },
  // { key: 'channelName', name: 'Partner Channel', editable: false, creatable: false, type: 'string' },
  {
    key: 'brandName',
    name: 'Brand',
    type: 'brands',
    link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } },
  },
  {
    key: 'channelName',
    name: 'Channel',
    type: 'brands',
    link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } },
  },
  {
    key: 'status',
    name: 'Status',
    editable: true,
    creatable: true,
    type: 'select',
    kind: 'status',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'verified', label: 'Verified' },
      { value: 'deleted', label: 'Deleted' },
    ],
  },
]

export default class ContactRequest extends React.Component {
  state = {
    edit: false,
  }

  onCancel = (e) => {
    if (this.props.id === 'create') {
      return this.props.onCancel()
    }
  }

  render() {
    const { id, details, loading, contacts } = this.props

    console.log(details)

    const Actions = () => {
      if (details.status === 'found') {
        return null
      }
      if (details.status !== 'unsuccessful') {
        return (
          <ConfirmDialog
            title={'Decline Contacts Request'}
            confirmation
            confirmationText={'Are you sure you want to decline this request?'}
            action={this.props.onDeclineRequest}
            confirnButtonLabel={'Decline'}
            confirnButtonProps={{
              variant: 'contained',
              color: 'primary',
            }}
          >
            <Button variant='contained' color='primary'>
              Decline request
            </Button>
          </ConfirmDialog>
        )
      }

      return (
        <RejectButton color='default' mr={2} disabled>
          Declined
        </RejectButton>
      )
    }

    return (
      <DashboardPage
        kind='details'
        title={'Contact Request'}
        status={id}
        noActions
        actions={Actions}
        thinking={loading}
      >
        <DetailsForm notEditable config={config} details={details} />

        {!loading && (
          <>
            <ChannelEntity
              channelId={details.channel}
              entityId={details.entityId}
              onCreate={this.props.onLoad}
            />
            <ChannelContacts
              channelId={details.channel}
              entityId={details.entityId}
            />
          </>
        )}
      </DashboardPage>
    )
  }
}
