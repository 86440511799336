import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import List from '../../components/List'
import Panel from '../../components/Panel'
import { BrandOpportunitiesStore } from '../../stores/opportunities'

@withRouter
@observer
class BrandOpportunities extends React.Component {
  constructor(props) {
    super(props)
    const { clientId } = props

    this.onLoadStores(clientId)
  }

  onLoadStores = id => {
    this.store = new BrandOpportunitiesStore(id)
  }

  // Opportunities
  onChangeSort = orderByNew => e => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value }}) => this.store.onChangeRowsPerPage(value)

  onChangeFilters = name => object => {
    let value = null
    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(name, value)
  }

  onAddOpportunity = () => {
    const { clientId, clientName } = this.props
    this.props.history.push(`/opportunities/create?brandId=${clientId}&brandName=${clientName}`)
  }

  onClickRow = (e, id) => this.props.history.push(`/opportunities/${id}`)

  render () {
    const { listProps } = this.store
    const opportunitiesListProps = {
      ...listProps,
      columns: [
        { id: 'n', disablePadding: true, label: 'ID' },
        { id: 'name', disablePadding: true, label: 'Name' },
        { id: 'status', disablePadding: true, label: 'Status', kind: 'status' }
      ],
      onChangeSort: this.onChangeSort,
      onChangeFilters: this.onChangeFilters,
      onChangePage: this.onChangePage,
      onChangeRowsPerPage: this.onChangeRowsPerPage,
      onClickRow: this.onClickRow,
      onAddOpportunity: this.onAddOpportunity
    }

    return (
      <Panel
        title="Opportunities"
        dataCy="addOpportunities"
        adding
        onAdd={opportunitiesListProps.onAddOpportunity}
      >
        <List
          searchable
          {...opportunitiesListProps} />
      </Panel>
    )
  }
}

export default BrandOpportunities
