import React from 'react'
import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'

const columns = [
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
  { id: 'name', disablePadding: true, label: 'Name' },
  { id: 'totalSpent', disablePadding: true, label: 'Total Spent' }
]

export default class Brands extends React.Component {
  render () {
    return (
      <DashboardPage
        kind="list"
        title="Brands"
        addDataCy="createBrand"
        addLabel="Create Brand"
        addLink="/brands/create"
      >
        <List
          columns={columns}
          {...this.props}
          emptyMessage={'No Brands matching the given criteria.'}
          searchable
        />
      </DashboardPage>
    )
  }
}
