import { find } from 'lodash'
import { observable, action, computed, toJS } from 'mobx'
import { Delete, Get, Post, Put } from '../utils/request'
import config from '../config'
import hasError from './request-message'


const convertToStrings = (data) => data.map(({ value }) => value)

const convertToOptions = (data = [], options) => data.map(value => find(options, ['value', value]))

class ClientStore {
  model
  discovery
  @observable id
  @observable n
  @observable name
  @observable onboardingStep
  @observable createdAt
  @observable status
  @observable credits
  @observable assisted
  @observable assistedLock
  @observable paywall
  @observable totalSpent
  @observable description
  @observable logoUrl
  @observable flags = {}
  @observable categories = []
  @observable classification = []
  @observable businessModels = []

  @observable loading = true
  @observable thinking = false

  constructor (id, model, discovery) {
    this.url = `/api/admin/${discovery ? 'discovery/' : ''}${model}s`
    this.model = model
    this.modelName = model === 'partner' ? 'Partner' : 'Brand'
    if (id !== 'create') {
      this.id = id
      this.load().then()
    } else {
      this.loading = false
    }
  }

  @action
  async load () {
    const { errorCode, [this.model]: model, message } = await Get(`${this.url}/${this.id}`)

    if (hasError(!errorCode, message)) {
      return message
    } else {
      for (let key in model) {
        if (model.hasOwnProperty(key)) {
          this[key] = model[key]
        }
      }
    }
    this.loading = false
  }

  @action
  async create ({ categories, businessModels, ...otherDetails }) {
    return await Post(`${this.url}`, {
      [this.model]: {
        ...otherDetails,
        categories: convertToStrings(categories),
        // businessModels: convertToStrings(businessModels)
      }
    })
  }

  @action
  async addCredits (credits) {
    const { message, [this.model]: model, success } = await Put(`${this.url}/${this.id}/add-credits`, { credits })
    if (!hasError(success, message, `${this.modelName} credits successfully updated`)) {
      for (let key in model) {
        if (model.hasOwnProperty(key)) {
          this[key] = model[key]
        }
      }
    }

    return { success }
  }

  @action
  async update ({ categories, intelligenceEnabled, ...details }) {
    let flags = {}

    try {
      flags = JSON.parse(toJS(this.flags))
    } catch (e) {}

    const { message, [this.model]: model, success } = await Put(`${this.url}/${this.id}`, {
      [this.model]: {
        ...details,
        // categories: convertToStrings(categories),
        flags: JSON.stringify({ ...flags, intelligenceEnabled: Boolean(intelligenceEnabled) })
      }
    })

    if (!hasError(success, message, `${this.modelName} details successfully updated`)) {
      for (let key in model) {
        if (model.hasOwnProperty(key)) {
          this[key] = model[key]
        }
      }
    }

    return { success }
  }

  @action
  async toggleArchive () {
    let result = {}
    const toArchive = this.status !== 'deleted'
    if (toArchive) {
      result = await Delete(`${this.url}/${this.id}`, {})
    } else {
      result = await Put(`${this.url}/${this.id}`, {
        brand: { status: 'active' }
      })
    }

    const { message, success } = result
    if (!hasError(success, message, `${this.modelName} successfully ${toArchive ? 'archived' : 'unarchived'} `)) {
      this.status = toArchive ? 'deleted' : 'active'
    }
    return { success }
  }

  @computed
  get details () {
    const {
      id, n, name, createdAt, assisted, assistedLock, paywall, onboardingStep,
      status, description, categories, credits, totalSpent, logoUrl
    } = this

    let flags = {}

    try {
      flags = JSON.parse(toJS(this.flags)) || {}
    } catch (e) {}

    const { intelligenceEnabled } = flags

    return {
      id, n, name, createdAt, status, description, logoUrl, credits, assisted, assistedLock, paywall, onboardingStep,
      categories: convertToOptions(toJS(categories), config.CLASSIFICATIONS), flags,
      totalSpent: `${config.NUMBER_CURRENCY_SYMBOL}${(totalSpent || 0).toFixed(4)}`,
      intelligenceEnabled
    }
  }

}

export default ClientStore
