import React from 'react'
import { find } from 'lodash'
import is from 'is_js'
import {
  Grid,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Button as MuiButton,
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Divider,
} from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'

import Panel from '../../components/Panel'
import List from '../../components/List'
import { EMAIL_VALIDATE_EXPRESSION } from '../../data/email-exclusions'

const columns = [
  { id: 'firstName', disablePadding: true, label: 'First name' },
  { id: 'lastName', disablePadding: true, label: 'Last name' },
  { id: 'jobTitle', disablePadding: true, label: 'Job Title' },
  { id: 'email', disablePadding: true, label: 'Email' },
  { id: 'phone', disablePadding: true, label: 'Phone' },
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
]

const Button = styled(MuiButton)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)
const TextField = styled(MuiTextField)(spacing)

const initialContact = {
  id: null,
  channel: null,
  firstName: null,
  lastName: null,
  jobTitle: null,
  email: null,
  phone: null,
  linkedin: null,
  twitter: null,
  instagram: null,
}

const NAMES_SOURCES = ['LinkedIn', 'Website', 'Facebook']
const PERSONAL_EMAIL_SOURCES = [
  'Apollo',
  'Hunter.io',
  'Snov.io',
  'Facebook',
  'Website',
]
const COMPANY_EMAIL_SOURCES = [
  'Apollo',
  'Hunter.io',
  'Snov.io',
  'Facebook',
  'Website',
]
const LINKED_ID_SOURCES = ['Sales Navigator']

const requiredFields = [
  { field: 'firstName', kind: 'text' },
  { field: 'lastName', kind: 'text' },
  { field: 'sourceFirstName', kind: 'text' },
  { field: 'sourceLastName', kind: 'text' },
]

const validate = (details, fields) => {
  let errors = null

  fields.map(({ field, kind }) => {
    if (!details[field]) {
      errors = { ...errors, [field]: 'Required field' }
    } else if (kind === 'email' && !is.email(details[field])) {
      errors = { ...errors, [field]: 'Please enter correct email' }
    } else if (kind === 'link' && !is.url(details[field])) {
      errors = { ...errors, [field]: 'Please enter correct URL' }
    } else if (
      kind === 'email' &&
      field === 'email' &&
      EMAIL_VALIDATE_EXPRESSION.test(details[field])
    ) {
      errors = { ...errors, [field]: 'Sorry, you can’t add role-based email.' }
    }
  })

  return errors
}

const SourceSelect = ({ id, value, options, label, error, onChange }) => {
  return (
    <FormControl style={{ width: '100%' }} error={Boolean(error)}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        autoWidth
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

const STRING_FIELDS = ['firstName', 'lastName', 'jobTitle']

const clearString = (str) => {
  if (!str) return str

  return str
    .replace(/\s{2,}/g, ' ')
    .replace(/\t/g, ' ')
    .trim()
    .replace(/(\r\n|\n|\r)/g, '')
}

const clearDetails = (details) => {
  let result = {}

  console.log(details)

  for (let key in details) {
    if (details.hasOwnProperty(key)) {
      if (STRING_FIELDS.includes(key)) {
        result[key] = clearString(details[key])
      } else {
        result[key] = details[key]
      }
    }
  }
  return result
}

export class ContactContactDialog extends React.Component {
  state = { ...initialContact, errors: {} }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      const {
        id,
        firstName,
        lastName,
        jobTitle,
        email,
        phone,
        linkedin,
        twitter,
        instagram,
        sourceFirstName,
        sourceLastName,
        sourceEmail,
        sourceLinkedin,
      } = this.props.contact

      this.setState({
        id,
        firstName,
        lastName,
        jobTitle,
        email,
        phone,
        linkedin,
        twitter,
        instagram,
        sourceFirstName,
        sourceLastName,
        sourceEmail,
        sourceLinkedin,
      })
    }
  }

  onChange =
    (field) =>
    ({ target: { value } }) => {
      const { errors } = this.state
      delete errors[field]
      this.setState({ [field]: value, errors })
    }

  onRemove = () => {
    const { id } = this.state
    this.props.onRemove(id)
  }

  onSave = (publish) => {
    const {
      id,
      firstName,
      lastName,
      jobTitle,
      email,
      phone,
      linkedin,
      twitter,
      instagram,
      sourceFirstName,
      sourceLastName,
      sourceEmail,
      sourceLinkedin,
    } = this.state

    let requiredContactFields = requiredFields

    requiredContactFields = [
      { field: 'email', kind: 'email' },
      { field: 'sourceEmail', kind: 'text' },
      ...requiredContactFields,
    ]

    const errors = validate(
      {
        firstName,
        lastName,
        email,
        linkedin,
        sourceFirstName,
        sourceLastName,
        sourceEmail,
        sourceLinkedin,
      },
      requiredContactFields
    )

    if (!errors) {
      this.props.onSave(
        {
          id,
          ...clearDetails({
            firstName,
            lastName,
            jobTitle,

            linkedin,
            twitter,
            instagram,
            email,
            phone,
            sourceFirstName,
            sourceLastName,
            sourceEmail,
            sourceLinkedin,
          }),
        },
        publish
      )
    } else {
      this.setState({ errors })
    }
  }

  render() {
    const { open, contact } = this.props
    const {
      firstName,
      lastName,
      jobTitle,
      email,
      phone,
      linkedin,
      twitter,
      instagram,
      sourceFirstName,
      sourceLastName,
      sourceEmail,
      sourceLinkedin,
      errors,
    } = this.state

    return (
      <Dialog
        fullWidth
        maxWidth='sm'
        data-cy='dialogContactContacts'
        open={open}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogTitle id='max-width-dialog-title'>Contact</DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                mb={2}
                fullWidth
                error={errors['firstName']}
                helperText={errors['firstName']}
                data-cy='nameContactContacts'
                label={'First name'}
                placeholder={'First name'}
                value={firstName}
                onChange={this.onChange('firstName')}
              />
            </Grid>

            <Grid item xs={4}>
              <SourceSelect
                id={'sourceFirstName'}
                error={errors['sourceFirstName']}
                value={sourceFirstName}
                options={NAMES_SOURCES}
                label={'First Name Source'}
                onChange={this.onChange('sourceFirstName')}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                mb={2}
                fullWidth
                error={errors['lastName']}
                helperText={errors['lastName']}
                data-cy='nameContactContacts'
                label={'Last name'}
                placeholder={'Last name'}
                value={lastName}
                onChange={this.onChange('lastName')}
              />
            </Grid>

            <Grid item xs={4}>
              <SourceSelect
                id={'sourceLastName'}
                error={errors['sourceLastName']}
                value={sourceLastName}
                options={NAMES_SOURCES}
                label={'Last Name Source'}
                onChange={this.onChange('sourceLastName')}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                mb={2}
                fullWidth
                error={errors['email']}
                helperText={errors['email']}
                label={'Personal Email'}
                data-cy='emailContactContacts'
                placeholder={'e.g. user@email.com'}
                value={email}
                onChange={this.onChange('email')}
              />
            </Grid>

            <Grid item xs={4}>
              <SourceSelect
                id={'sourceEmail'}
                error={errors['sourceEmail']}
                value={sourceEmail}
                options={PERSONAL_EMAIL_SOURCES}
                label={'Personal Email Source'}
                onChange={this.onChange('sourceEmail')}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                mb={2}
                fullWidth
                error={errors['linkedin']}
                helperText={errors['linkedin']}
                label={'LinkedIn'}
                data-cy='positionContactContacts'
                placeholder={'e.g. https://linkedin.com/user'}
                value={linkedin}
                onChange={this.onChange('linkedin')}
              />
            </Grid>

            <Grid item xs={4}>
              <SourceSelect
                id={'sourceLinkedin'}
                error={errors['sourceLinkedin']}
                value={sourceLinkedin}
                options={LINKED_ID_SOURCES}
                label={'LinkedIn Source'}
                onChange={this.onChange('sourceLinkedin')}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <TextField
                mb={2}
                fullWidth
                label={'Job Title'}
                data-cy='positionContactContacts'
                placeholder={'e.g. Owner'}
                value={jobTitle}
                onChange={this.onChange('jobTitle')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                mb={2}
                fullWidth
                label={'Phone'}
                data-cy='phoneContactContacts'
                placeholder={'Phone'}
                value={phone}
                onChange={this.onChange('phone')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                mb={2}
                fullWidth
                label={'Instagram'}
                data-cy='positionContactContacts'
                placeholder={'e.g. https://instagram.com/user'}
                value={instagram}
                onChange={this.onChange('instagram')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                mb={2}
                fullWidth
                label={'Twitter'}
                data-cy='positionContactContacts'
                placeholder={'e.g. https://twitter.com/user'}
                value={twitter}
                onChange={this.onChange('twitter')}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions px={7} py={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button
                variant='outlined'
                color='default'
                mr={2}
                onClick={this.props.onCancel}
              >
                Cancel
              </Button>
              {/*{*/}
              {/*  id && this.props.onRemove && <Button*/}
              {/*    mr={2}*/}
              {/*    variant="outlined"*/}
              {/*    onClick={this.onRemove}*/}
              {/*  >*/}
              {/*    Remove*/}
              {/*  </Button>*/}
              {/*}*/}
            </Grid>
            <Grid
              xs={12}
              sm={6}
              container
              direction='row'
              justify='flex-end'
              alignItems='center'
            >
              <Button
                onClick={() => this.onSave()}
                variant='outlined'
                color='primary'
              >
                Save
              </Button>
              {(!contact.status || contact.status === 'pending') && (
                <>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => this.onSave(true)}
                    variant='outlined'
                    color='primary'
                  >
                    Save & Publish
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default class ContactContacts extends React.Component {
  constructor(props) {
    super(props)
    this.state = { contact: initialContact, open: false }
  }

  onAddContact = () => this.setState({ open: true, contact: initialContact })

  onEditContact = (e, id) => {
    const contact = find(this.props.contacts, ['id', id])
    if (contact && ['pending'].includes(contact.status)) {
      this.setState({ open: true, contact })
    }
  }

  onSaveContact = async (details, publish) => {
    const { success } = await this.props.onSave(details, publish)

    if (success) {
      this.setState({ open: false, contact: initialContact })
    }
  }

  onRemoveContact = async (id) => {
    const { success } = await this.props.onRemove(id)
    if (success) {
      this.setState({ open: false, contact: initialContact })
    }
  }

  onCancelEditContact = () =>
    this.setState({ open: false, contact: initialContact })

  render() {
    const { thinking, contacts } = this.props

    return (
      <Panel
        title='Contacts'
        adding
        dataCy='addContactContacts'
        thinking={thinking}
        onAdd={this.onAddContact}
      >
        <List
          columns={columns}
          items={contacts.map(({ email, phone, ...rest }) => {
            return {
              ...rest,
              email,
              phone,
            }
          })}
          onClickRow={this.onEditContact}
          emptyMessage={'No Contacts are added.'}
          emptyActionText={'Add a Contact'}
          onEmptyAction={this.onAddContact}
          disablePagination
        />

        <ContactContactDialog
          variant={'request'}
          open={this.state.open}
          contact={this.state.contact}
          onSave={this.onSaveContact}
          onRemove={this.onRemoveContact}
          onCancel={this.onCancelEditContact}
        />
      </Panel>
    )
  }
}
