import React from 'react'
import styled from 'styled-components'
import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'
import { Button } from '@material-ui/core'
import { CSVReader } from 'react-papaparse'
import { GetBlob } from '../../utils/request'

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Created' },
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
  {
    id: 'brandName', disablePadding: true, label: 'Brand', kind: 'link',
    link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } }
  },
  {
    id: 'channelName', disablePadding: true, label: 'Channel', kind: 'link',
    link: { title: 'Channel', pattern: `/channels/{id}`, keys: { id: 'channel' } }
  }
]

const CSVHolder = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: inherit;
`

export default class ContactRequests extends React.Component {
  state = { thinking: false, showUpload: false, data: null }

  handleOnDrop = (parsed) => {
    const result = parsed.map(({ data }) => data)
    result.slice(0, 1)
    this.setState({ showUpload: true, data: result })
  }

  handleOnRemoveFile = () => {
    this.setState({ showUpload: false, data: null })
  }

  onUploadCSV = async () => {
    const { data } = this.state
    this.setState({ thinking: true })

    await this.props.onUploadCSV(data)

    this.setState({ thinking: false, showUpload: false, data: null })
  }

  onExportToCSV = async () => {
    await GetBlob(`/api/admin/contact-requests/csv?filters[status]=requested`)
  }


  render () {
    const { showUpload, thinking } = this.state

    return (
      <DashboardPage
        // noActions
        kind="list"
        title="Contact Requests"
        addLabel="Export to CSV"
        addClick={this.onExportToCSV}
      >
        {/*<CSVHolder>*/}
        {/*  {*/}
        {/*    !thinking && (*/}
        {/*      <CSVReader*/}
        {/*        onDrop={this.handleOnDrop}*/}
        {/*        addRemoveButton*/}
        {/*        removeButtonColor='#659cef'*/}
        {/*        onRemoveFile={this.handleOnRemoveFile}*/}
        {/*      >*/}
        {/*        <span>Drop CSV file here or click to upload.</span>*/}
        {/*      </CSVReader>*/}
        {/*    )*/}
        {/*  }*/}


        {/*  {*/}
        {/*    showUpload && <Button*/}
        {/*      disabled={thinking}*/}
        {/*      variant="contained"*/}
        {/*      color="secondary"*/}
        {/*      onClick={this.onUploadCSV}*/}
        {/*    >*/}
        {/*      { thinking ? 'Uploading.....' : 'Upload data' }*/}
        {/*    </Button>*/}
        {/*  }*/}

        {/*</CSVHolder>*/}


        <List
          columns={columns}
          {...this.props}
          emptyMessage={'No Partners matching the given criteria.'}
          searchable
        />
      </DashboardPage>
    )
  }
}
