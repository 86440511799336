import List from './list'

export class BrandOpportunitiesStore extends List {
  url = '/api/admin/opportunities'

  constructor(brand) {
    super()
    this.order = 'desc'
    this.filters = { brand }
    this.perPage = 10
    this.orderBy = 'createdAt'
    this.itemsField= 'opportunities'
    this.getList().then()
  }
}

export default class OpportunitiesStore extends List {
  url = '/api/admin/opportunities'

  constructor() {
    super()
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField= 'opportunities'
    this.getList().then()
  }
}
