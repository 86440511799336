import List from './list'
import { action, computed, toJS } from 'mobx'
import { formatDate } from '../utils/format'
import { Post, Put } from '../utils/request'
import hasError from './request-message'

import { dataToDetails, contactToPayload } from '../utils/contacts'

export default class EntitiesStore extends List {
  url = '/api/admin/entities'

  constructor() {
    super()
    this.order = ''
    this.orderBy = ''
    this.itemsField = 'entities'
    this.getList().then()
  }

  @action
  async publish(id) {
    return await Put(`/api/admin/entities/${id}/publish`, {})
  }

  @action
  async createEntity({ channel, ...details }, publish) {
    const payload = {
      channelId: (channel || {}).id,
      data: contactToPayload(details),
    }

    const { success, message, entity } = await Post(`/api/admin/entities`, {
      entity: payload,
    })

    if (!hasError(success, message, 'Entity successfully created')) {
      if (publish) {
        await this.publish(entity.id)
      }
      this.getList().then()
    }

    return { success: true }
  }

  @computed
  get entities() {
    return toJS(this.items).map((item) => {
      const { id, data, channel, createdAt, status } = item

      return {
        id,
        channel: (channel || {}).id,
        channelName: (channel || {}).domain,
        createdAt: formatDate(createdAt),
        ...dataToDetails(data),
        status,
      }
    })
  }
}
