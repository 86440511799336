import React from 'react'
// import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Paper,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody, TablePagination,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Tooltip,
  TableSortLabel,
  Typography,
  Button as MuiButton
} from '@material-ui/core'
import { PostAddOutlined as MuiTableIcon } from '@material-ui/icons'
import { spacing } from '@material-ui/system'
import styled, {css} from 'styled-components'
import Search from './Search'
import Status from './Status'
import FilteredSearch from './FilteredSearch/Search'
import { darken } from 'polished'
import DateRangePicker from './DateRangePicker'
import Link from './Link'


const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(12)}px);
`;

const TableRowHead = MuiTableRow
const TableRow = styled(MuiTableRow)`
  cursor: ${props => !props.disabled && 'pointer'};
`
const TableCellBase = styled(MuiTableCell)`
  ${props => props.nowrap && css`
    white-space: nowrap;
  `}
`
const TableEmpty = styled.div`
  padding: 2em 2em 4em 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5em;
  flex-direction: column;
`

const TableIcon = styled(MuiTableIcon)`
  width: 2.5em;
  height: 2.5em;
  display: block;
  margin-bottom: 0.5em;
  color: ${props => props.theme.palette.grey[500]}
`
const Button = styled(MuiButton)(spacing)

const FiltersContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 63px;
`

const TableToolbarContainer = styled.div`
  border-radius: 2px;
  padding: 0 45px;
  min-height: 63px;
  background-color: #bbdefb;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  ${props => darken(0.05, props.theme.header.background)};
  justify-content: space-between;
  
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  
`;

class TableHead extends React.Component {
  render() {
    const {
      bulkState,
      onSelectAll,
      onRequestSort,
      bulkOperations,
      headerRows,
      order,
      orderBy
    } = this.props;

    return (
      <MuiTableHead>
        <TableRowHead>
          {
            bulkOperations && <MuiTableCell padding="checkbox">
              <Checkbox
                indeterminate={bulkState === 'indeterminate'/*numSelected > 0 && numSelected < rowCount*/}
                checked={bulkState === 'checked'/*rowCount > 0 && numSelected === rowCount*/}
                onChange={onSelectAll}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </MuiTableCell>
          }
          {
            headerRows.map(row => (
                <MuiTableCell
                  key={row.id}
                  align={row.numeric ? 'right' : 'left'}
                  // padding={row.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={onRequestSort(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                </MuiTableCell>
              ),
              this
            )
          }
        </TableRowHead>
      </MuiTableHead>
    );
  }
}

const TableCellLink = ({ value, item, link, onClick }) => {
  const url = ({ pattern, keys }) => {
    let result = pattern
    for (let key in keys) {
      if (keys.hasOwnProperty(key)) {
        result = result.replace(`{${key}}`, item[keys[key]])
      }
    }
    return result
  }

  return <Link link={url(link)} value={value} title={link.title} />
}

const TableCell = ({ column, item, onClick }) => {
  return (
    <TableCellBase key={column.id} disabled={item.disabled}
                  nowrap={column.nowrap}
                  align={column.numeric ? 'right' : 'left'}
                  onClick={event => ['actions', 'link'].indexOf(column.kind) >= 0  ? null : onClick(event, item.id)}>
      { column.kind === 'status' && <Status value={item[column.name || column.id]} /> }
      { !column.kind && item[column.name || column.id] }
      { column.kind === 'actions' && item[column.name || column.id]() }
      {
        column.kind === 'link' && <TableCellLink value={item[column.name || column.id]} item={item} link={column.link} onClick={event => onClick(event, item.id)}/>
      }
    </TableCellBase>
  )
}

export default class List extends React.Component {
  static propTypes = {
    searchable: PropTypes.bool,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    columns: PropTypes.array,
    items: PropTypes.array,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    page: PropTypes.number,
    perPage: PropTypes.number,
    perPageOptions: PropTypes.array,
    onClickRow: PropTypes.func,
    emptyMessage: PropTypes.string,
    emptyActionText: PropTypes.string,
    onEmptyAction: PropTypes.func
  }

  static defaultProps = {
    searchable: false,
    search: '',
    title: '',
    placeholder: 'Search',
    columns: [],
    items: [],
    order: 'asc',
    orderBy: '',
    page: 0,
    perPage: 10,
    perPageOptions: [5, 10, 25],
    onClickRow: () => {},
    onChangeSort: () => {},
    emptyMessage: 'No records matching the given criteria.',
    onEmptyAction: () => {}
  }

  state = { selected: [] }

  onSelect = id => e => {
    const { selected } = this.state
    const index = selected.indexOf(id)
    if (index < 0) {
      selected.push(id)
    } else {
      selected.splice(index, 1)
    }
    this.setState({ selected })
  }

  isItemSelected = id => {
    const { selected } = this.state
    const index = selected.indexOf(id)
    return index >= 0
  }

  onSelectAll = () => {
    if (['indeterminate', 'checked'].indexOf(this.bulkState) >= 0) {
      this.setState({ selected: [] })
    } else {
      this.setState({ selected: this.props.items.map(({ id }) => id)})
    }
  }

  get bulkState () {
    const { items } = this.props
    const { selected } = this.state
    const { length: selectedLength } = selected
    const { length: itemsLength } = items
    if (selectedLength && itemsLength > selectedLength) {
      return 'indeterminate'
    } else if (selectedLength && itemsLength === selectedLength) {
      return 'checked'
    }
    return null
  }

  render () {
    const {
      bulkOperations,
      searchable,
      placeholder,
      columns,
      items,
      total,
      order,
      orderBy,
      page,
      perPage,
      perPageOptions,
      onClickRow,
      filters,
      disablePagination = items.length === 0,
      emptyMessage,
      emptyActionText,
      onEmptyAction,
      onChangeFilters,
      filteredSearchProps,
      toolbarActions: ToolbarActions,
      onToolbarActionClick,
      searchComponent: SearchComponent,
      totalRow: TotalRow
    } = this.props

    const { selected } = this.state

    return (
        <Paper>
          {
            (searchable || bulkOperations) && <FiltersContainer>
              {
                !!this.bulkState && <TableToolbarContainer>
                  <Typography component={'h1'}>{selected.length} item{ selected.length > 1 ? 's' : ''} selected</Typography>
                  <div>
                    <ToolbarActions selected={selected} onClick={onToolbarActionClick}/>
                  </div>
                </TableToolbarContainer>
              }

              {
                !!filteredSearchProps && searchable && <React.Fragment>
                  <FilteredSearch {...filteredSearchProps} />
                  <DateRangePicker
                    dates={[filters.startAt, filters.endAt]}
                    onChange={onChangeFilters('date')}
                  />
                </React.Fragment>
              }
              { searchable && !filteredSearchProps && <Search placeholder={placeholder} onChange={(value) => this.props.onChangeFilters('name')(value)}/> }
            </FiltersContainer>
          }

          {items.length !== 0 && <TableWrapper>
            <MuiTable aria-labelledby="tableTitle">
              <TableHead
                bulkOperations={bulkOperations}
                bulkState={this.bulkState}
                headerRows={columns}
                order={order}
                orderBy={orderBy}
                onSelectAll={this.onSelectAll}
                onRequestSort={this.props.onChangeSort}
                rowCount={total}
              />
              <TableBody>
                {
                  items.map(item => (
                      <TableRow
                        disabled={item.disabled}
                        hover={!item.disabled}
                        tabIndex={-1}
                        key={item.id}
                        selected={this.isItemSelected(item.id)}
                      >
                        {
                          bulkOperations && <MuiTableCell
                            padding="checkbox"
                            onClick={this.onSelect(item.id)}
                          >
                            <Checkbox
                              checked={this.isItemSelected(item.id)}
                              inputProps={{ 'aria-labelledby': 'labelId' }}
                            />
                          </MuiTableCell>
                        }

                        {
                          columns.map(column => {
                            return (
                              <TableCell key={column.id} column={column} item={item} onClick={onClickRow}/>
                            )
                          })
                        }
                      </TableRow>
                    )
                  )
                }
                { !!TotalRow && <TotalRow /> }
              </TableBody>
            </MuiTable>
          </TableWrapper>}
          {
            items.length === 0 && <TableEmpty>
              { !!emptyActionText && <TableIcon /> }
              <Typography variant={"body1"}>{emptyMessage}</Typography>
              {
                !!emptyActionText && <Button mt={5} variant={"outlined"} color={"secondary"} onClick={event => onEmptyAction(event)}>{emptyActionText}</Button>
              }
            </TableEmpty>
          }
          {
            !disablePagination &&
            <TablePagination
              rowsPerPageOptions={perPageOptions}
              component="div"
              count={total}
              rowsPerPage={perPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page'
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page'
              }}
              onChangePage={this.props.onChangePage}
              onChangeRowsPerPage={this.props.onChangeRowsPerPage}
            />
          }
        </Paper>
    )
  }
}
